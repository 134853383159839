#bio {
  min-height: 450.05px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.bio-image-desktop {
  object-fit: cover;
  display: block;
  width: 50%;

  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.bio-image-mobile {
  object-fit: cover;
  display: none;

  @media only screen and (max-width: 800px) {
    display: block;
    height: 50%;
  }
}

.bio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: clamp(15px, 2vw, 21px);
  font-weight: 300;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 4vw;
  padding-right: 4vw;
  display: inline;

  @media only screen and (max-width: 800px) {
    font-size: 15px;
  }
}

p {
  width: 50%;

  @media only screen and (min-width: 800px) {
    padding: 64px;
  }
}
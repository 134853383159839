#navbar {
  position: fixed;
  z-index: 8000;
  width: 100%;
  box-sizing: border-box;
  padding: 2vw 4vw;
  pointer-events: auto;
  display: flex;
  justify-content: space-between;
  background: transparent;
  backdrop-filter: blur(10px);
  transition: ease .5s;
  border-radius: 0 0 16px 16px;
}

@media (max-width: 768px) {
  #navbar {
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  height: 100%;
}

.navbar_section {
  flex-wrap: nowrap;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar_link a {
  position: relative;
  text-decoration: none;
  color: inherit;
  font-weight: 400;
}

.navbar_link a:not([href*="futurefilter"]):not([href*="soundcloud"]):not([href*="mailto"]):not([href*="ra.co"]) {
  position: relative;
  text-decoration: none;
  color: inherit;
  font-weight: 400;
}

.navbar_link a:not([href*="futurefilter"]):not([href*="soundcloud"]):not([href*="mailto"]):not([href*="ra.co"])::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: -2px;
  background-color: #ffd7ba;
  transition: width 0.3s ease-in-out;
}

.navbar_link a:not([href*="instagram"]):not([href*="soundcloud"]):not([href*="mailto"]):not([href*="ra.co"]):hover::after {
  width: 100%;
}
.App {
  text-align: center;
  padding-bottom: 20vh;
}

.email-contact {
  margin-top: 100px; /* Adjust this value as needed */
  font-size: 16px; /* Optional: Adjust font size */
  text-align: center; /* Optional: Center the email text */
}


html {
  color: #fff;
  background-image: url('../src/assets/cover.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  scroll-behavior: smooth;
}

a {
  color: white !important;
  text-decoration: none;
}

a:hover {
  color: #ffd7ba !important;
  text-shadow: 4px #ffd7ba;
}

.padding-top {
  padding-top: 6vw;

  @media (max-width: 768px) {
    padding-top: 4vw;

  }
}
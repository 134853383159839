.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(6vh, 10vw, 100px);
  font-weight: bolder;
  z-index: 999;
  filter: contrast();
  mix-blend-mode: difference;

  @media only screen and (min-width: 800px) {
    display: flex;
  }
}

.hero-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.gowun-batang-bold {
  font-family: "Gowun Batang", serif;
  font-weight: 700;
  font-style: normal;
}
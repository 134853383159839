.dates-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

h2 {
  margin: 0;
  font-weight: 400;
}

.planning-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

}

ul {
  list-style-type: none;
  padding: 0;
}

.date {
  width: 100%;
  padding: 10px 0;
  font-size: clamp(15px, 2vw, 21px);
  display: flex;
  font-weight: 300;
}

.date-holder {
  border-right: solid white 1px;
  width: 40px;
  margin-right: 8px;
}

.year-section {
  margin-top: 20px;
  margin-bottom: 20px;
}